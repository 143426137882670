<template lang="pug">
  .root
    component-header-login
    div(class="container-flex")
      div(class="content-center")
        div(class="login-content")
          h4 ログインの方はこちら
          input(placeholder="メールアドレス" v-model="form.email" class="input-g-border")
          input(placeholder="パスワード" type="password" v-model="form.password" class="input-g-border")
          div(v-if="ui.loginfailed" style="color:red") IDまたはパスワードが正しくありません

          div(class="Login-button" @click="onLogin()") <img src="/img/login_p.png">

          div(class="login-kiroku")
            label(for="") ログイン情報を記録する
            input(type="checkbox" v-model="form.isSave")
    
    component-footer-login
</template>

<style src="./Index.scss" lang="scss" scoped>
</style>

<script>
import { getEmailSaved } from '@/utils/auth'

export default {
  name: "LoginIndex",
  components: {},
  data() {
    return {
      ui: {
        loginfailed: false
      },
      form: {
        email: undefined,
        password: undefined,
        isSave: true,
      },
    }
  },
  props: {},
  computed: {},
  watch: {},
  mounted() {},
  async created() {
    const emailSaved = getEmailSaved();

    if (process.env.VUE_APP_ENV === 'DEV') {
      if (process.env.VUE_APP_DEV_MAIL) {
        this.form.email = process.env.VUE_APP_DEV_MAIL;
      } else {
        this.form.email = 'streamer8@akipro.com';
      }
      this.form.password = 'password';
    } else if (emailSaved) {
      this.form.email = emailSaved;
    }
  },
  updated() {},
  destroyed() {},
  methods: {
    async onLogin() {
      try {
        await this.$store.dispatch('user/login', this.form);
        this.$router.push({ name: 'TopIndex' });
      } catch(err) {
        console.log(err);
        this.form.email = ''
        this.form.password = ''
        this.ui.loginfailed = true;
      }
    }
  }
};
</script>
